export default function checkRights(user, network = false) {
    let rez = false;
    if (!user) {
      rez = false;
    } else if (user.role === "SUPER_ADMIN") {
      rez = true;
    } else if (user.role === "OWNER" && (!network || !network.role)) {
      rez = true;
    } else if (user.role === "MANAGER" && (!network || !network.role)) {
      rez = true;
    } else if (
      (network
      && network.role)
      && (network.role === "OWNER"
      || network.role === "SUPER_ADMIN"
      || network.role === "MANAGER")
    ) {
      rez = true;
    } else {
      rez = false;
    }
    return rez;
}