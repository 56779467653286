export const arrUniq = (arr, networkId, orderRules, isEqual) => {
    let rez = [];
    arr.forEach((item) => {
      if (rez.every(el => el.id !== item.id)) {
        rez.push(item);
      }
    });
    rez = sortArr(rez, networkId, orderRules, isEqual);
    return rez;
};


export const arrAllUniq = (arr) => {
    let rez = [];
    // arr.forEach((network) => {
    //   const tmpnetwork = {};
    //   Object.assign(tmpnetwork, network);
    //   const tmpAps = [];
    //   tmpnetwork.aps.forEach((item) => {
    //     if (tmpAps.every(el => el.id !== item.id)) {
    //       tmpAps.push(item);
    //       tmpnetwork.aps = sortArr(tmpAps, tmpnetwork.id);
    //     }
    //   });
    //   rez.push(tmpnetwork);
    // });
    // rez = this.sortNetworksArr(rez);
    return rez;
};


export const renderSecurityType = (security) => {
  switch (security) {
    case 0:
      return <p>WPA</p>;
    case 1:
      return <p>WPA2</p>;
    case 2:
      return <p>WPA/WPA2 Mixed Mode</p>;
    case 4:
      return <p>WPA2 Enterprise</p>;
    default:
      return <p>None</p>;
  }
};



export const getItemStyle = (isDragging, draggableStyle, activeAPId = {}, item = {}, color) => {
  const darkMode = localStorage.getItem("dark_mode");

  let elementColor = "#fff";
  if (darkMode === "true") {
    if (item.id === +activeAPId.id) {
      elementColor = "#6b434380";
    } else {
      elementColor = "#181A1B";
    }
  } else if (item.id === +activeAPId.id) {
    elementColor = "#efe6e6";
  }
  let bgColor = "white";
  if (darkMode === "true") bgColor = "#181A1B";
  if (isDragging) bgColor = "lightgreen";
  return {
    userSelect: "none",
    height: "58px",
    position: "relative",
    marginBottom: "10px",
    // change background colour if dragging
    background: bgColor,
    backgroundColor: elementColor,
    borderRadius: "7px",
    overflow: "hidden",
    border: `1px solid ${color}`,
    ...draggableStyle,
  };
};

export const sortArr = (arr, networkId, orderRules, isEqual) => {
    let rez = false;
    if (orderRules[networkId] && !isEqual(orderRules[networkId], {})) {
      Object.keys(orderRules[networkId]).forEach((field) => {
        arr.sort((a, b) => { // eslint-disable-line
          return a.order_item > b.order_item ? 1 : -1;
        });
        if (field === "name" || field === "status") {
          rez = arr.sort((a, b) => {
            if (orderRules[networkId][field] === "asc") {
              let s = 0;
              if (a[field] > b[field]) {
                s = 1;
              } else if (a[field] < b[field]) {
                s = -1;
              }
              return s;
            }
            if (a[field] > b[field]) {
              return -1;
            }
            return 1;
          });
        } else {
          rez = arr.sort((a, b) => {
            if (orderRules[networkId][field] === "asc") {
              let s = 0;
              if (+a[field] > +b[field]) {
                s = 1;
              } else if (+a[field] < +b[field]) {
                s = -1;
              }
              return s;
            }
            if (+a[field] > +b[field]) {
              return -1;
            }
            return 1;
          });
        }
      });
    } else {
      rez = arr.sort((a, b) => {
        if (a.order_item > b.order_item) {
          return 1;
        }
        return -1;
      });
    }
    return rez || arr;
  };