/* eslint-disable react/prop-types */
import TextInput from "../Input/TextInput";
import { Formik, Form } from "formik";
import { Modal, Button as AntButton, Divider } from "antd";
import Button from "../Button";
import "./formModal.css"

const FormModal = ({
    handleSubmit,
    itemToEdit,
    handleCancel,
    initialValues,
    title,
    formFields,
    options = [],
    width = "50%"
}) => {
    return (
        <Formik
          initialValues={initialValues}
          enableReinitialize
        //   validationSchema={addSchoolTypeSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm)
            resetForm()
        }}
        >
            {({ values, handleChange, handleBlur, errors, submitForm, setFieldValue }) => (
                <Modal
                style={{"padding": "0 !important"}}
                title={
                    <div>
                        <div className="px-5 py-3">
                            <h2 className="flex flex-col gap-2">{itemToEdit ? `Edit ${title}` : `Add ${title}`}</h2>
                        </div>
                        <Divider style={{ margin: '0 0 0 0' }} key="divider2" />
                    </div>
                }
                open={true}
                onCancel={handleCancel}
                width={width}
                footer={
                    <div className="w-full">
                        <Divider style={{ margin: '0 0 0 0' }} key="divider2" />
                        <div className="w-full px-5 py-3 gap-3 flex justify-end">
                            <AntButton key="back" onClick={handleCancel}>
                            Cancel
                            </AntButton>
                            <Button key="submit" text={itemToEdit ? "Edit" : "Save"} type="primary" htmlType="submit" onClick={submitForm} />
                        </div>
                    </div>
                }
            >
                <Form className="p-5" >
                    {formFields && formFields.map((field, index) => {
                        switch(field.inputFieldType){
                            case "textField":
                                return <TextInput
                                        name={field.name}
                                        title={field.title}
                                        placeholder={field.placeholder}
                                        requiredField={field.is_required}
                                        errors={errors}
                                        values={values}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        key={index}
                                    />
                            // case "textAreaField":
                            //     return <TextArea
                            //                     name={field.name}
                            //                     title={field.title}
                            //                     placeholder={field.placeholder}
                            //                     requiredField={field.is_required}
                            //                     errors={errors}
                            //                     values={values}
                            //                     handleBlur={handleBlur}
                            //                     handleChange={handleChange}
                            //                     key={index}
                            //                 />
                            // case "checkField":
                            //     return <CheckBoxInput
                            //                 label={field.title}
                            //                 name={field.name}
                            //                 setFieldValue={setFieldValue}
                            //                 values={values}
                            //             />
                            // case "selectField":
                            //     return <CustomDropDownInput
                            //             title={field.title}
                            //             multipleSelection={false}
                            //             name={field.name}
                            //             placeholder={field.placeholder}
                            //             requiredField={field.is_required}
                            //             errors={errors}
                            //             options={options}
                            //             handleBlur={handleBlur}
                            //             handleChange={handleChange}
                            //             values={values}
                            //             displayLabel={field.displayLabel}
                            //             idLabel={field.idLabel}
                            //             setFieldValue={setFieldValue}
                            //         />
                            // case "dateField":
                            //     return <DateInput
                            //             name={field.name}
                            //             title={field.title}
                            //             placeholder={field.placeholder}
                            //             requiredField={field.is_required}
                            //             errors={errors}
                            //             values={values}
                            //             handleBlur={handleBlur}
                            //             handleChange={handleChange}
                            //             setFieldValue={setFieldValue}
                            //         /> 
                        }} 
                            
                    )}
                </Form>
            </Modal>
            )}

        </Formik>
        
    );
};

export default FormModal;
