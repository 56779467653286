import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from "react-router-dom";
import router from 'router/router';
import { AuthProvider } from 'context/AuthContext';
import ToasterContainer from 'components/uielements/ToasterContainer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ToasterContainer />
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
