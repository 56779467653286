import { Menu } from "antd"
import { useState, useEffect } from "react";
import {
    NotificationOutlined,
    ImportOutlined,
    WifiOutlined,
    IssuesCloseOutlined,
    UserSwitchOutlined,
    AppstoreOutlined
  } from '@ant-design/icons';
import axiosClient from "utils/axiosClient";
import MenuItem from "../uielements/MenuItem";
import { supabase } from "utils/supabase";
import Header from "./Header";
import { useLocalStorage } from "hooks/useLocalStorage";
import { Link } from "react-router-dom";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const [networks, setNetworks] = useState([])

    const getNetworks = async () => {
        try {
            const { data } = await axiosClient.get('/networks?limit=999');
            const { data: sharedNetworks } = await axiosClient.get('/shared/companies/networks?limit=999');

            const networks = []
            if(data.data.items && data.data.items.length > 0){
                networks.push(...data.data.items)
            }

            if(sharedNetworks.data && sharedNetworks.data.length > 0){
                sharedNetworks.data.forEach(net => {
                    networks.push(...net.networks)
                })
            }

            setNetworks(networks ? networks.toSorted((a, b) => a.name.localeCompare(b.name)) : [])
        } catch (error) {}
    }

    const [localNetworks, setLocalNetworks] = useState([])
    const getLocalNetworks = async () => {
        try {
            const { data } = await supabase.from("network")
                .select()
                .order('id', {ascending: false})
                .limit(2000)
                setLocalNetworks(data)
        } catch (error) {}
    }

    useEffect(() => {
        getNetworks()
        getLocalNetworks()
    }, [])

    const [activeIndex, setActiveIndex] = useLocalStorage("activeIndex", 1)

    const menuItems = [
        {
            id: 1,
            icon: <AppstoreOutlined />,
            title: "Dashboard",
            link: "/"
        },
        {
            id: 2,
            icon: <WifiOutlined />,
            title: "Local Networks",
            link: "/networks",
            networks: localNetworks
        },
        {
            id: 3,
            icon: <WifiOutlined />,
            title: "Networks",
            link: "/networks",
            networks: networks
        },
        {
            id: 4,
            icon: <NotificationOutlined />,
            title: "Alerts",
            link: "/alerts"
        },
        {
            id: 5,
            icon: <ImportOutlined />,
            title: "Captive Portal",
            link: "/captive-portal"
        },
        {
            id: 6,
            icon: <UserSwitchOutlined />,
            title: "Clients",
            link: "/clients"
        }
    ]

  return (
    <aside className={`absolute left-0 top-0 w-[260px] z-50 overflow-y-auto duration-300 ease-linear h-screen bg-[#01486E] lg:static lg:translate-x-0 ${ sidebarOpen ? "translate-x-0" : "-translate-x-full" }`} >
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="mt-5">
                <Menu mode="inline" theme="dark" >
                    {menuItems?.map((item, index) => (
                        <MenuItem key={item.id} index={index+1} icon={item.icon} title={item.title} link={item.link} networks={item?.networks ? item?.networks : null} setActiveIndex={setActiveIndex} activeIndex={activeIndex} />
                    ))}
                    <li className="text-sm flex items-center justify-between gap-x-4 cursor-pointer select-none duration-300 ease-in-out py-2 px-3 lg:px-5 text-md cursor-pointer bg-[#01486E] hover:bg-[#07517A] text-gray-400 font-medium">
                        <Link to="mailto:support@renumesh.africa">
                            <div className="flex gap-2 items-end text-md hover:text-gray-400">
                                <span className="block">
                                    <IssuesCloseOutlined />
                                </span>
                                <div className="duration-200 flex-1 gap-x-4">
                                    Report Issues
                                </div>
                            </div>
                        </Link>
                    </li>
                </Menu>
            </div>
    </aside>
  )
}

export default Sidebar