import networkIcon from 'assets/icons/networks.svg'
import userIcon from 'assets/icons/user.svg'
import nodeIcon from 'assets/icons/share-sign.svg'

function Stats({topStats}) {

  const stats = [
    {
      icon: networkIcon,
      total: topStats?.networkTotal,
      label: "Networks"
    },
    {
      icon: nodeIcon,
      total: topStats?.apTotal,
      label: "Nodes"
    },
    {
      icon: userIcon,
      total: topStats?.networkUserTotal,
      label: "Wifi Clients"
    },
    // {
    //   icon: networkIcon,
    //   total: 0,
    //   label: "Utilization"
    // },
  ]
  
  return (
    <section className='flex gap-2 md:gap-6 mb-5'>
      {stats?.map((stat, index) => (
        <div className='w-full flex justify-center gap-6 items-center h-full py-5 lg:py-10 bg-white rounded-md cursor-pointer shadow-sm hover:shadow-md border-[1px] border-gray-100' key={index}>
          <img src={stat.icon} alt="" width={56}/>
          <div className='flex flex-col items-center'>
            <h2 className='text-4xl lg:text-6xl font-bold text-[#4281fd]'>{stat.total}</h2>
            <h2 className='text-sm'>{stat.label}</h2>
          </div>
        </div>
      ))}
    </section>
  )
}
export default Stats