import { Map, InfoWindow, GoogleApiWrapper, Circle } from 'google-maps-react';
import { containerStyle, mapStyles2 } from './basicStyles';

function BasicMaker({ google, networks, zoom = 10 }) {
  const styles = mapStyles2;

  // Create a new StyledMapType
  const styledMap = new google.maps.StyledMapType(styles, { name: "Styled Map" });

  const mapLoaded = (mapProps, map) => {
    // Apply the styled map to your map instance
    map.mapTypes.set('styled_map', styledMap);
    map.setMapTypeId('styled_map');
  };

  return (
    <div className='h-[calc(80vh-170px)] flex border-[1px] rounded-md overflow-hidden '>
      <Map 
        google={google} 
        zoom={zoom}
        initialCenter={networks?.length > 0 ? {lat: networks[0].lat, lng: networks[0].lng} : { lat: 0.332401, lng: 32.58878 }}
        options={{ styles: mapStyles2 }}
        containerStyle={containerStyle}
        onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        markers={[]}
        mapTypeControl={false}
      >
        {networks && networks.length > 0 && networks.map((marker, index) => (
            <Circle
              key={index}
              radius={200 / (1/zoom)}
              center={marker}
              // onMouseover={() => console.log('mouseover')}
              // onClick={() => console.log('click')}
              // onMouseout={() => console.log('mouseout')}
              strokeColor={marker?.status === "Active" ? '#00b16a' : '#ff0000'
                
              }
              strokeOpacity={0.5}
              strokeWeight={5}
              fillColor='#FF0000'
              fillOpacity={0}
            />
        ))}

        <InfoWindow onClose={() => {}}>
            <div>
              <h1>Here</h1>
            </div>
        </InfoWindow>
      </Map>
    </div>
  )
}

// export default BasicMaker

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
  libraries: ["places", "visualization", "geometry"],
})(BasicMaker);