import { Toaster } from 'react-hot-toast';

function ToasterContainer() {
  return (
    <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
            className: '',
            duration: 5000,
            style: {
            background: '#fff',
            color: '#000',
            },

            success: {
            duration: 3000,
            theme: {
                primary: 'green',
                secondary: 'black',
            },
            },
        }}
    />
  )
}

export default ToasterContainer