import { Select, Table } from "antd"
import { useParams } from "react-router-dom"
import axiosClient from "utils/axiosClient"
import { useState, useEffect } from "react"
import getUpDownData from "utils/upDownData"
import NodesColors from "utils/nodesColors"
import moment from "moment"
import { localizeTimeDisplay } from "utils/timeHelpers"
import SSIDOverview from "../SSIDOverview"
import TabTitle from "components/uielements/TabTitle"
import ClientChart from "../ClientChart"
import NetworkChart from "../NetworkChart"
import { useAuth } from "context/AuthContext"
import { supabase } from "utils/supabase"
import BasicMaker from "components/GoogleMaps/BasicMaker"
import APList from "./APList"
import SolarPowerChart from "../SolarPowerChart"
import { renderSecurityType } from "./utils"
import { cloneDeep } from "lodash"
import getColor from "utils/getColor"

function NetworkPage() {
    const { id } = useParams()
    const [network, setNetwork] = useState(null)
    const [upDownDataObj, setUpDownDataObj] = useState(null)
    const [helpSsidData, setHelpSsidData] = useState([])
    const [netData, setNetData] = useState([])
    const [clientsData, setClientsData] = useState([])
    const [loading, setLoading] = useState(false)
    const [range, setRange] = useState("day")
    const { setNetworkData, user } = useAuth()
    const [apListTypes, setApListTypes] = useState("network")

    const [nodeId, setNodeId] = useState(null)
    const [nodeIdsArray, setNodeIdsArray] = useState(null)
    const [helpData, setHelpData] = useState([])
    const [solarGraphData, setSolarGraphData] = useState([])
    const [actualSolarState, setActualSolarState] = useState([])

    useEffect(() => {
        getNetwork();
        getCaptivePortals();
        getNetworkAPs();
        getSolarGraphData();
    }, [id, range])

    const getNetwork = async () => {
        setLoading(true)

        try {
          if(+id > 10) {
            const { data } = await axiosClient.get(`/networks/${id}`);

          if(data?.data){
              setNetwork(data.data)
              setNetworkData(data.data)

              const res = getUpDownData(data.data)
              setUpDownDataObj(res)

              const { data: APstat } = await axiosClient.get(
                range === "month" ? 
                `/networks/${id}/access-points/statistic?date_start=${new Date().getFullYear()}-0${new Date().getMonth()}-${(new Date().getDate()+1)}-${new Date().getHours()}-00&end_start=${new Date().getFullYear()}-0${new Date().getMonth() + 1}-${(new Date().getDate())}-${new Date().getHours()}-00&interval=86400` 

                : 
                range === "day" ?
                `/networks/${id}/access-points/statistic?date_start=${new Date().getFullYear()}-0${new Date().getMonth() + 1}-${(new Date().getDate() - 1)}-${new Date().getHours()}-00&end_start=${new Date().getFullYear()}-0${new Date().getMonth() + 1}-${(new Date().getDate())}-${new Date().getHours()}-00&interval=3600`
                :
                `/networks/${id}/access-points/statistic?date_start=${new Date().getFullYear()}-0${new Date().getMonth() + 1}-${(new Date().getDate())}-${new Date().getHours() - 2}-00&end_start=${new Date().getFullYear()}-0${new Date().getMonth() + 1}-${(new Date().getDate())}-${new Date().getHours()}-00&interval=300`
              );

              makeNetworkData(APstat.data)
              // makeHelpSsidData(APstat.data)

              const { data: ssidData } = await axiosClient.get(`/networks/${id}/ssid-statistic`);

              makeHelpSsidData(ssidData.data)
              makeData(ssidData.data)
              
          }
          
          } else {
              const { data, } = await supabase.from("network")
                .select('*, ssid(*), network_actual_state(*)')
                .eq('id', +id)
                .single()

                if(data){
                  setNetwork(data)
                  setNetworkData(data)

                  const res = getUpDownData(data.network_actual_state)
                  setUpDownDataObj(res)

                  const { data: APstat } = await supabase.from("access_point_status")
                  .select()

                  makeNetworkData([{name: data.name, ssid: data.id, stats: APstat}])


                  const { data: ssidData } = await supabase.from("ssid_states")
                  .select()
                  .eq('ssid_id', +id)

                  makeHelpSsidData([{name: data.name, ssid: data.id, stats: ssidData}])
                  makeData([{name: data.name, ssid: data.id, stats: ssidData}])
                }
          }
        } catch (error) {
          // console.log(error)
        }
        
        
        setLoading(false)
    }

    let countC = 0;
    let countT = 0;
    const makeNetworkData = (gatewayGraphData, capacity = "Mbps") => {
        const time = new Date()
        const data = [];
        for (let i = 0; i < getNumRange(); i += 1) {
          const tmp = {
            capacity: 0,
            throughput: 0,
            textCapacity: 0,
            textThroughput: 0,
            name: "",
          };
          gatewayGraphData.forEach((item) => {
            if (item.stats && item.stats[i] && item.stats[i].created_at) {
              const d = localizeTimeDisplay(time, item.stats[i].created_at);
              tmp.name = moment(d).format(range === "month" ? "MMM-DD" : "h:mm a");
              tmp.capacity += (+item.stats[i].upload + +item.stats[i].download);
              tmp.throughput += (
                +item.stats[i].upload_throughput + +item.stats[i].download_throughput
              );
              tmp.textCapacity = tmp.capacity;
              tmp.textThroughput = tmp.throughput;
            }
          });
          if (!data[i]) {
            data[i] = {
              capacity: null,
              throughput: null,
            };
          }
          data[i].textCapacity = tmp.textCapacity;
          data[i].textThroughput = tmp.textThroughput;
          data[i].capacity += tmp.capacity;
          data[i].throughput += tmp.throughput;
          data[i].name = tmp.name;
          if (capacity !== "Mbps") {
            data[i].capacity /= 1000;
            data[i].throughput /= 1000;
          }
        }
        // clear nulls data
        data.forEach((val, key) => {
          if (+val.capacity > +val.throughput) {
            countC += 1;
          } else if (+val.capacity < +val.throughput) {
            countT += 1;
          }
          if (val.capacity === 0) {
            data[key].capacity = null;
          }
          if (val.throughput === 0) {
            data[key].throughput = null;
          }
          if (val.name === "") {
            data[key] = {};
          }
        });
        if (countC === countT) countT -= 1;

        setNetData(data)
        return data;
      };

    const nodesColors = new NodesColors();
    const makeHelpSsidData = (ssidData) => {
        const helpSsidData2 = [];
        ssidData.forEach((ssid) => {
        helpSsidData2.push({
            id: ssid.ssid,
            name: ssid.name.trim(),
            color: nodesColors.getColor(ssid.ssid).up,
        });
        });

        setHelpSsidData(helpSsidData2)
    }


    const getNumRange = () => {
        let r = range === "month" ? 30 : 25;
        if (range === "day") r = 24;
        return r;
    };

    const makeData = (ssidData) => {
        setLoading(true)
        const data = [];
        for (let i = 0; i < getNumRange(); i += 1) {
          const tmp = {};
          ssidData.forEach((item) => {
            if (item.stats && item.stats[i] && item.stats[i].created_at) {
              tmp.name = moment(item.stats[i].created_at).format(range === "month" ? "MMM-DD" : "h:mm a");
              tmp[item.ssid] = +item.stats[i].clients;
            }
          });
          data.push(tmp);
        }

        setClientsData(data)
        setLoading(false)
      };


    const [captivePortalsList, setCaptivePortalsList] = useState([])
    const getCaptivePortals = async () => {
        setLoading(true)
        try{
          const { data } = await axiosClient.get('/captive-portals');
          setCaptivePortalsList(data?.data?.items ? data?.data?.items : [])
        } catch (error){}
        setLoading(false)
    }

    const findCaptivePortalName = (id) => {
        if (captivePortalsList) {
          const index = captivePortalsList.findIndex(portal => +portal.id === +id);
          if (index !== -1) {
            return captivePortalsList[index].name;
          }
        }
        return id;
      };

    const columns = [
        {
          title: "SSID",
          key: "ssid",
          render: object => (
            <div className="croppedName">
              <p>{object.name}</p>
            </div>
          ),
        },
        {
          title: "Clients",
          dataIndex: 'clients',
          key: "clients",
        },
        {
          title: "Security",
          dataIndex: 'security',
          key: "security",
          render: (_, record) => renderSecurityType(record.security)
        },
        {
          title: "Captive Portal",
          key: "captive_portal",
          render: (_, record) => record.captive_portal ? findCaptivePortalName(record.captive_portal) : "-"
        },
        {
          title: "Actions",
          key: "actions",
        },
      ];



    // run mad here




    // end run mad here

    const [networkAPs, setNetworkAPs] = useState([])
  const getNetworkAPs = async () => {
    if(id > 10){
      const { data } = await axiosClient.get(`/networks/${id}/access-points`);
      if(data?.data){
        setNetworkAPs(data.data)
      }
    } else {
      return null
    }
  }

  const getSolarGraphData = async () => {
    if(id > 10){
      const { data } = await axiosClient.get(`/networks/${id}/access-points/solar-states?date_start=2024-07-10&date_end=2024-07-11`);
      if(data?.data){
        makeSolarData(data.data)
        setSolarGraphData(data.data)

      }
    } else {
      return null
    }
  }

  const getNotEmptyNodes = (nodesIds) => {
    const rezNodesIds = [];
    Object.keys(solarGraphData?.stats).forEach((nodeStates) => {
      let add = false;
      if (nodesIds.indexOf(+nodeStates) !== -1) {
        solarGraphData?.stats[nodeStates].forEach((stat) => {
          if (+stat.input_power > 0 && !add) {
            add = true;
          }
        });
        if (add) {
          rezNodesIds.push(+nodeStates);
        }
      }
    });
    return rezNodesIds;
  };


  const makeSolarData = (solarGraphData2) => {
    const data = [];
    setNodeIdsArray(solarGraphData2 && [...solarGraphData2.nodes])

    if (
        (nodeId && !solarGraphData2.stats[nodeId])
        || (
            !solarGraphData2
            || !solarGraphData2.stats
            || Object.keys(solarGraphData2.stats).length === 0
        )
    ) {
        return [];
    }
    
    let newNodesIdsArray = cloneDeep(nodeIdsArray);

    if (!solarGraphData2 || !solarGraphData2 || !solarGraphData2.nodes) return false;
    // if (!nodeId && !isEqual(nodeIdsArray, getNotEmptyNodes(solarGraphData2?.nodes))) {
    //     newNodesIdsArray = getNotEmptyNodes(solarGraphData?.nodes);
    //     // this.setState({
    //     //     nodeIdsArray: newNodesIdsArray,
    //     // });
    //     setNodeIdsArray(newNodesIdsArray)
    // }

    let draw = true;
    if (nodeId) {
        solarGraphData.stats[nodeId].forEach((item) => {
          if (
            (+item.input_power !== 0
              || +item.average_input_power !== 0)
            && !draw
          ) {
            draw = true;
          }
          const tmp = {
            name: moment(+item.created_at).format(range === "month" ? "MMM-DD" : "h:mm a"),
            // name: this.localizeTimeDisplay(+item.created_at * 1000).format("h:mm a"),
            battery: item.battery
              ? (+item.battery).toFixed(0)
              : 0,
            averagePower: item.average_input_power
              ? (+item.average_input_power)
              : "0.00",
          };
          if (+item.average_input_power > 0) {
            tmp.averagePower = +item.average_input_power;
          }
          if (+item.input_power > 0) {
            tmp.inputPower = +item.input_power;
          }
          data.push(tmp);
        });
    } else {
        const SGD = cloneDeep(solarGraphData2?.stats);
        const helpData2 = [];

        Object.keys(SGD).forEach((nodeStates) => {
        // //   if (newNodesIdsArray.indexOf(+nodeStates) !== -1) {
            const name = `${nodeStates}_data`;
            helpData2.push({
              id: +nodeStates,
              name: networkAPs.find(node => +node.id === +nodeStates)
                ? networkAPs.find(node => +node.id === +nodeStates).name
                : null,
              colors: { ...getColor(nodeStates) },
            });
            setHelpData(helpData2)

            SGD[nodeStates].forEach((stat, key) => {
                if (+stat.input_power !== 0 && !draw) {
                  draw = true;
                }

                if (!data[key]) {
                  data[key] = {};
                  // data[key].name = localizeTimeDisplay(+stat.created_at * 1000).format("h:mm a");
                  data[key].name = moment(+stat.created_at).format(range === "month" ? "MMM-DD" : "h:mm a");
                }
                if (+stat.input_power > 0) {
                  data[key][name] = +stat.input_power;
                }
            });
            // }
            // });

        });
      }
      setActualSolarState(data)
      // return data;
    };


   return (
    <main>
        <TabTitle title={network ? network.name : "Network"} />
        {network && <SSIDOverview network={network} upDownDataObj={upDownDataObj} />}

        <div className="flex my-5 gap-2">
            <Select defaultValue="day"
              onChange={e => setRange(e)} className="w-[150px]"
            >
                <Select.Option value="hour">Last 2 hours</Select.Option>
                <Select.Option value="day">Last day</Select.Option>
                <Select.Option value="month">Last month</Select.Option>
            </Select>
            <Select defaultValue="" value="">
                <Select.Option value="">Network Overview</Select.Option>
                <Select.Option value="day">Gateway Capacity</Select.Option>
                <Select.Option value="month">Gateway Utilization</Select.Option>
            </Select>
        </div>

        <NetworkChart countC={countC} countT={countT} netData={netData} /> {/* Network chart */}
        <ClientChart helpSsidData={helpSsidData} clientsData={clientsData} /> {/* Client chart */}
        
        <div>
            <Table
                columns={columns}
                dataSource={network?.ssid ? network?.ssid : []}
                loading={loading}
            />
        </div>

        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-1">
            <div className="border border-black flex rounded-md mb-3 h-10 overflow-hidden">
              <div className={`w-full px-3 py-1 h-10 flex justify-center items-center cursor-pointer ${apListTypes === "network" && "text-white bg-blue-950"}`} onClick={() => setApListTypes("network")}>
                <h3 className="text-ellipsis line-clamp-1 text-xs">{network?.name}</h3>
              </div>
              <div className={`w-full px-1 py-3 h-10 flex justify-center items-center cursor-pointer ${apListTypes === "all" && "text-white bg-blue-950"}`} onClick={() => setApListTypes("all")}>
                <h3 className="text-ellipsis line-clamp-1 text-xs">All nodes</h3>
              </div>
            </div>
            <APList network={network} networkAPs={networkAPs} me={user} />
          </div>
          <div className="col-span-2 p-0">
            <BasicMaker
              networks={networkAPs && networkAPs.length > 0 ? networkAPs : []}
            />
          </div>
        </div>

        <SolarPowerChart helpData={helpData} apList={networkAPs && networkAPs.length > 0 ? networkAPs : []} solarGraphData={actualSolarState} />
    </main>
  )
}

export default NetworkPage