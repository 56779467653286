import { useState, useEffect } from "react";
import { DatePicker, Select, Table, Tag } from "antd";
import { supabase } from "utils/supabase";
import { format } from "date-fns";
import Button from "components/uielements/Button";
import TabTitle from "components/uielements/TabTitle";
import axiosClient from "utils/axiosClient";
import { exportToExcel, getSignalStrength } from "./utils";
import _ from "lodash";

const Option = Select.Option

function Clients() {
    const [clients, setClients] = useState([])
    const [filterClients, setFilterClients] = useState([])
    const [loading, setLoading] = useState(false)
    const [networks, setNetworks] = useState([])
    const [selectedNetwork, setSelectedNetwork] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const getNetworks = async () => {
      setLoading(true)
      try {
        const response = await axiosClient.get(`/networks?limit=999`);
  
        if(response && response.status !== 401){
          const { data } = response
          const { data: sharedNetworks} = await axiosClient.get('/shared/companies/networks?limit=999');
  
          const networks2 = []
            if(data.data.items && data.data.items.length >0){
              networks2.push(...data.data.items)
            }
  
            if(sharedNetworks.data && sharedNetworks.data.length >0){
                sharedNetworks.data.forEach(net => {
                  networks2.push(...net.networks)
                })
            }
  
          setNetworks(networks2?.toSorted((a, b) => a.name.localeCompare(b.name)))

        } 
      } catch(error) {
        // console.log(error)
      }
      setLoading(false)
    }

    const getClients = async () => {
      setLoading(true)
      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage - 1;

      if(selectedNetwork){
        const { data, error } = await supabase.from("client_details")
          .select('*')
          .eq('network_id', selectedNetwork)
          .order('created_at', {ascending: false})
          .limit(100)
          // .range(start, end);

          setClients(data)

          console.log(error)

          if(data){
            setFilterClients(data)
          }
      } else {
        const { data, error } = await supabase.from("client_details")
          .select('*')
          .order('created_at', {ascending: false})
          .limit(100)
          // .range(start, end);

          setClients(data)

          if(data){
            setFilterClients(data)
          }
      }
      
      setLoading(false)
    }

    useEffect(() => {
      getClients()
      getNetworks()
    }, [selectedNetwork])

    const columns = [
        {
          title: "Date",
          dataIndex: 'created_at',
          key: "created_at",
          render: (_, record) => record.created_at && format(record.created_at, "dd/MM/yyyy hh:mma"),
          sorter: (a, b) => b.created_at - a.created_at,
        },
        {
          title: "MAC",
          dataIndex: 'macaddrs',
          key: "macaddrs",
          render: (_, record) => <p className="uppercase">{record.macaddrs}</p>
        },
        {
          title: "UpTime",
          dataIndex: 'uptime',
          key: "uptime",
          render: (_, record) => record.uptime && record.uptime.split(" ")[0] + "s"
        },
        {
          title: "Downtime",
          dataIndex: 'downtime',
          key: "downtime",
        },
        {
          title: "Connectivity",
          dataIndex: 'downtime',
          key: "downtime",
          render: (_, record) => <>
          <Tag color={["Excellent", "Good"].includes(getSignalStrength(record?.avg_signal?.split(" ")[0] && +record.avg_signal.split(" ")[0])) ? "green" : ["Poor", "Very Poor"].includes(getSignalStrength(record?.avg_signal?.split(" ")[0] && +record.avg_signal.split(" ")[0])) ? "red" : "default"}>{getSignalStrength(record?.avg_signal?.split(" ")[0] && +record.avg_signal.split(" ")[0])}</Tag>
          </>
        },
        {
          title: "SSID",
          dataIndex: 'ssid',
          key: "ssid",
        },
        {
          title: "Ap Name",
          dataIndex: 'ap_name',
          key: "ap_name",
        },
        {
          title: "Device",
          dataIndex: 'device_name',
          key: "device_name",
          render: (_, record) => `${record.device_name} ${record.ipaddrs}`,
          sorter: (a, b) => a.device_name.localeCompare(b.device_name),
          sortDirections: ['descend'],
        },
      ];

  const [networkAPs, setNetworkAPs] = useState([])
  const getNetworkAPs = async (e) => {
    setNetworkAPs([])
    if(selectedNetwork){
      const { data } = await axiosClient.get(`/networks/${e}/access-points`);
      if(data?.data){
        setNetworkAPs(data.data)
      }
    } else {
      return null
    }
  }

  return (
    <div>
      <TabTitle title="Clients" />
      <div className="flex gap-5 mx-3 justify-between">
        <div className="flex gap-5 mb-5">
          <DatePicker onChange={async (e) => {
            setLoading(true)
            if(e){

              const startDate = new Date(e);
              const endDate = new Date(e);
              endDate.setDate(endDate.getDate() + 1);

              const { data, error } = await supabase.from("client_details")
                  .select('*')
                  // .eq('network_id', e)
                  // .lte('created_at', "2024-07-24")
                  .gte('created_at', startDate.toISOString())
                  .lt('created_at', endDate.toISOString())
                  .order('created_at', {ascending: false})
                  .limit(100)

              if(data){
                setFilterClients(data)
              }
            } else {
              if(filterClients){
                setFilterClients(clients)
              }
            }
            setLoading(false)
          }} />
          <Select onChange={async (e) => {
              setLoading(true)
              if(e){
                setLoading(true)
                setSelectedNetwork(e)
                getNetworkAPs(e)

                const { data, error } = await supabase.from("client_details")
                  .select('*')
                  .eq('network_id', e)
                  .order('created_at', {ascending: false})
                  .limit(100)

                if(data){
                  setFilterClients(data)
                }

                setLoading(false)
              } else {
                setFilterClients(clients)
              }
              setLoading(false)
            }}
            defaultValue={null}
            className="w-[150px]"
          >
            <Option value={null}>Select Network</Option>
            {networks && networks.length > 0 && networks?.map(net => (
              <Option key={net.id} value={net.id}>{net.name}</Option>
            ))}
          </Select>

          {selectedNetwork && networkAPs && networkAPs.length > 0 &&
            <Select onChange={async (e) => {
                setLoading(true)
                if(e){

                  const { data, error } = await supabase.from("client_details")
                    .select('*')
                    .eq('network_id', selectedNetwork)
                    .eq('ap_name', e)
                    .order('created_at', {ascending: false})
                    .limit(100)

                  if(data){
                    setFilterClients(data)
                  }

                  // const filteredClients = clients?.filter(client => client.ap_name === e)
                  // setFilterClients(filteredClients)
                } else {
                  setFilterClients(clients)
                }
                setLoading(false)
              }}
              defaultValue={null}
              className="w-[150px]"
            >
              <Option value={null}>Select AP Name</Option>
              {networkAPs.map((ap, index) => (
                <Option key={index} value={ap.name}>{ap.name}</Option>
              ))}
            </Select>
          }
        </div>

        <Button type="primary" onClick={exportToExcel} text="Export" />
      </div>
      {/* { filterClients && */}
        <div className="mx-3 my-2 border-[1px] overflow-hidden">
            <Table
                loading={loading}
                dataSource={_.cloneDeep(filterClients)}
                columns={columns}
                rowKey={(record) => (Math.random() + 1 + record.id).toString(36).substring(7)}
                scroll={{ x: 500 }} 
            />
        </div>
      {/* } */}
    </div>
  )
}

export default Clients