import { NavLink, Link } from "react-router-dom";
import { ShareAltOutlined } from '@ant-design/icons';
import { BsChevronDown } from "react-icons/bs";

function MenuItem({
    icon,
    title,
    link,
    networks=false,
    index,
    activeIndex,
    setActiveIndex
}) {
    return (
        <li className="rounded-md">
            <NavLink to={networks && networks.length > 0 ? "#" : link}
            className={({isActive}) => `text-sm flex items-center justify-between gap-x-4 cursor-pointer select-none duration-300 ease-in-out py-2 px-3 lg:px-5 text-md cursor-pointer bg-[#01486E] hover:bg-[#07517A] text-gray-400 font-medium ${isActive && index === activeIndex ? "hover:text-white text-white bg-[#07517A]" : "hover:text-gray-400"} `}

            onClick={() => {
                setActiveIndex(index)
            }}
                >

                    <div className="flex gap-2 items-end text-md">
                        <span className="block">
                        {icon}
                        </span>
                        <div className="duration-200 flex-1 gap-x-4">
                            {title}
                        </div>
                    </div>

                    {networks && (
                    <BsChevronDown
                        size={12}
                    />
                )}

            </NavLink>

            {networks && index === activeIndex && networks.map((network) => 
                          <Link to={`/networks/${network.id}`} key={`networks-${network.id}`}>
                            <div className="flex items-center gap-2 pl-4 px-2 py-4 cursor-pointer bg-[#004062]">
                              {network.is_shared ? 
                                <ShareAltOutlined className={`w-3 h-3 rounded-full ml-4 ${network.status === "Active" ? "text-[#00b16a]" : network.status === "Partial Offline" ? "text-[#ffbf00]" : "text-[#ff0000]"}`} />
                              :
                              <div className={`w-2 h-2 rounded-full ml-4 ${network.status === "Active" ? "bg-[#00b16a]" : network.status === "Partial Offline" ? "bg-[#ffbf00]" : "bg-[#ff0000]"}`}></div>
                              }
                              <p className="">{network.name}</p>
                        </div>
                          </Link>
                        )}
        </li>
    );
}

export default MenuItem;
