import LOGO from "assets/sidebar_logo.png";

function Header({ sidebarOpen, setSidebarOpen }) {
  return (
    <div className="flex items-center justify-center w-full py-2 px-5 h-[70px] border-b-[1px] border-gray-400 bg-[#03537E]">
        <img className="w-16 shadow-lg" src={LOGO} alt="RENUMESH" />

        <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="text-white text-3xl bg-light-white rounded p-2 lg:hidden"
        >
            {/* <FaBars size={20} /> */}
        </button>
    </div>
  )
}
export default Header