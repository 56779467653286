import { useAuth } from "context/AuthContext"
import TopbarUser from "./TopbarUser"
import { useLocation } from 'react-router-dom';
import { MdEdit } from "react-icons/md";

function Navbar() {
  const { user, logout, networkData } = useAuth()
  const location = useLocation();


  return (
    <nav className="flex items-center bg-white justify-between px-5 py-4 absolute top-0 right-0 left-0 lg:left-[260px] border-b-[1px] border-gray-200 z-40 h-[70px] shadow-sm"
    >
      <section>
        {location.pathname.startsWith("/networks/") && (
            <div className="flex items-center gap-0">
              <div>
                <h2 className="w-[150px] md:w-[200px] overflow-hidden line-clamp-1 text-ellipsis font-extrabold text-neutral-600">{networkData?.name}</h2>
                <p className="w-[150px] md:w-[200px] overflow-hidden line-clamp-1 text-ellipsis text-sm text-neutral-600">{networkData?.full_address}</p>
              </div>
              <div>
              {networkData?.name && <MdEdit size={22} className="text-gray-500 cursor-pointer" /> }
              </div>
                
            </div>
        )
        }
      </section>

      <div className="w-full flex justify-end">
          <TopbarUser user={user} logout={logout} />
      </div>
    </nav>
  )
}

export default Navbar