import { format } from "date-fns";
import * as XLSX from 'xlsx';

export const getSignalStrength = (signal) => {
    if(signal > -30) {
        return "Excellent"
    } else if (signal > -50){
        return "Good"
    } else if (signal > -60){
        return "Fair"
    } else if (signal > -70){
        return "Poor"
    } else if (signal > -90){
        return "Very Poor"
    } else {
        return "N/A"
    }
}

const transformData = (data) => {
    return data.map(item => {
      return {
        TIMESTAMP: format(item.created_at, "dd-MM-yyyy    hh:mm a"),
        SSID: item.ssid,
        "MAC ADDRESS": item.macaddrs.toUpperCase(),
        "NETWORK": item.network_name,
        "CONNECTED AT": format(item.connected_at, "dd-MM-yyyy   hh:mm a"),
        "AP NAME": item.ap_name,
        "AP MAC": item.ap_mac.toUpperCase(),
        "ClIENT IP": item.ipaddrs,
        "DEVICE NAMES": item.device_name,
        "CONNECTIVITY": getSignalStrength(item?.avg_signal?.split(" ")[0] && +item.avg_signal.split(" ")[0])
      };
    });
  }

export const exportToExcel = (filterClients) => {
    const transformedData = transformData(filterClients)
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Define a bold style for the headers
    const boldHeaderStyle = {
      font: { bold: true }
    };

  // Apply the bold style to the headers
  const range = XLSX.utils.decode_range(worksheet['!ref']);

  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = XLSX.utils.encode_cell({ c: C, r: range.s.r });
    if (!worksheet[cellAddress]) continue;
    if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {};
    worksheet[cellAddress].s = { ...worksheet[cellAddress].s, ...boldHeaderStyle };
  }

  // Set the column widths
  const columnWidths = [
    { wch: 20 }, { wch: 30 }, { wch: 20 }, { wch: 30 },{ wch: 20 },
    { wch: 30 },
    { wch: 20 },
    { wch: 30 },
  ];

  worksheet['!cols'] = columnWidths;

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Export the workbook to an Excel file
  XLSX.writeFile(workbook, "Clients.xlsx");
  };