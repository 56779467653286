function SSIDOverview({network, upDownDataObj}) {
  return (
    <div className="flex justify-between">
        {network?.active_ap ? 
            <div className="flex gap-2 items-end px-3 py-2">
                <h2 className={`text-5xl font-semibold ${network.status === "Active" ? "text-[#00b16a]" : network.status === "Partial Offline" ? "text-[#ffbf00]" : "text-[#ff0000]"}`}>{network.active_ap}/{network.total_ap}</h2>
                <h2 className="font-medium">active nodes</h2>
            </div>
        :
            <div className="flex gap-2 items-end px-3 py-2">
                <h2 className={`text-5xl font-semibold ${network.status === "Active" ? "text-[#00b16a]" : network.status === "Partial Offline" ? "text-[#ffbf00]" : "text-[#ff0000]"}`}>{network?.network_actual_state?.active_aps ?? 0}/{network?.network_actual_state?.active_aps ? (network?.network_actual_state?.active_aps + network?.network_actual_state?.inactive_aps) : 0}</h2>
                <h2 className="font-medium">active nodes</h2>
            </div>
        }
                <div className="flex gap-1 items-end px-3 py-2">
                    <h2 className="text-2xl md:text-4xl lg:text-5xl font-semibold text-[#4281fd]">
                        {upDownDataObj?.download ?? 0} 
                        &#8595; 
                        / 
                        {upDownDataObj?.upload ?? 0}  
                        &#8593;</h2>
                    <h2 className="font-medium">capacity ({upDownDataObj.points ?? "Mbps"})</h2>
                </div>
                <div className="flex gap-2 items-end px-3 py-2">
                    <h2 className="text-5xl font-semibold text-[#4281fd]">{network?.clients ? network.clients : network?.network_actual_state?.clients ?? 0}</h2>
                    <h2 className="font-medium">users</h2>
                </div>
            </div>
  )
}
export default SSIDOverview