import { useState, useEffect } from 'react'
import { Form, Select, Input } from 'antd'
import Button from 'components/uielements/Button'
import getUpDownData from 'utils/upDownData'
import checkRights from 'utils/checkRights'
import { useAuth } from 'context/AuthContext'
import { DeleteOutlined } from '@ant-design/icons'
import Notification from 'components/uielements/Notification'
import { Modal } from 'antd'
import FormModal from 'components/uielements/FormModal'


const Option = Select.Option
const { confirm } = Modal;

function NetworkList({ networks }) {
    const { user } = useAuth()
    const [filteredNetworks, setFilteredNetworks] = useState(networks)
    const [sortBy, setSortBy] = useState("name")
    const [addNetModal, setAddNetModal] = useState(false)

    useEffect(() => {
        updateFilteredNetworks()
    }, [networks])

    const updateFilteredNetworks = () => setFilteredNetworks(networks) 

    const deleteNetwork = async() => {}

    const showConfirm = async (id) => {
        const data = networks.find(network => +network.id === +id);
        confirm({
            title: `Are you sure you want to delete "${data.name}" network?`,
            onOk() {
                deleteNetwork({ id }).then(() => {
                Notification(
                    "success",
                    "Success",
                    "Network has been deleted",
                );
                }).catch((error) => {
                    Notification("error", "ERROR", error.body.error_description);
                });
            },
            onCancel() {},
            okText: "OK",
            cancelText: "Cancel",
        });
    }

    const handleFilter = (e) => {
        setFilteredNetworks(networks && networks?.filter(net => net.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1))
    }

    const handleSort = (e) => {
        setSortBy(e)
        if(e === "name"){
            setFilteredNetworks(networks && networks.sort((a,b) => a[e].localeCompare(b[e])))
        } else {
            setFilteredNetworks(networks && networks.sort((a,b) => b[e] - a[e]))
        }
    }

    const handleCancel = () => {setAddNetModal(false)}

    const handleSubmit = async () => {}

    const formFields = [
        {
            name: "name",
            title: "Network name",
            placeholder: "Enter network name",
            is_required: true,
            inputFieldType: "textField"
        },
        {
            name: "location",
            title: "Location",
            placeholder: "Enter location",
            is_required: true,
            inputFieldType: "textField"
        },
    ]

    const initialValues = {}

  return (
    <div className='px-5 py-3 w-full'>
        <div className="flex justify-between w-full flex-wrap">
            <h2 className='font-bold text-3xl'>Networks</h2>

            {addNetModal && <FormModal
                handleSubmit={() => {}}
                handleCancel={handleCancel}
                title="Network"
                formFields={formFields}
                initialValues={initialValues}
            />}

            <div>
                <Form
                    className="isoCardInfoForm"
                    autoComplete="off"
                    >
                    <div className="ant-form-item-control">
                        <div className="flex gap-2 items-center">
                        <p>Sort by</p>
                        <div id="sort">
                            <Select
                            className="w-[250px]"
                            getPopupContainer={() => document.getElementById("sort")}
                            defaultValue="name"
                            onChange={handleSort}
                            >
                            <Option value="name">name</Option>
                            <Option value="download">download</Option>
                            <Option value="upload">upload</Option>
                            <Option value="clients">active users</Option>
                            <Option value="active_ap">active nodes</Option>
                            </Select>
                        </div>
                        </div>
                    </div>
                </Form>
            </div>

            <div className="table-control-col first">
                <Form
                    className="search-input-form"
                    autoComplete="off"
                >
                    <Form.Item style={{ marginBottom: "0px" }} key="search">
                        <Input
                            allowClear
                            placeholder="Search"
                            type="text"
                            className={""}
                            name="search"
                            key="search"
                            onChange={handleFilter}
                        />,
                    </Form.Item>
                </Form>

            </div>

            <Button onClick={() => {setAddNetModal(true)}} text="Create Network" />
        </div>

        <div className="flex gap-3 flex-wrap w-full mt-5">
            {filteredNetworks && filteredNetworks.length > 0 ? filteredNetworks.map(network => (
                <div key={network.id} className={`px-5 py-3 border-2 rounded-md w-52 ${network.status === "Active" ? "border-[#00b16a]" : network.status === "Partial Offline" ? "border-[#ffbf00]" : "border-[#ff0000]"}`}>
                    <h3 className='line-clamp-1 text-lg font-semibold mb-2'>{network.name}</h3>
                    <p>
                        {getUpDownData(network) && getUpDownData(network).download}
                        <span>&#8595;</span>
                        { "/" }
                        {getUpDownData(network) && getUpDownData(network).upload}
                        <span>&#8593;</span>
                        { " " }
                        {getUpDownData(network) && getUpDownData(network).points}
                    </p>
                    <p>
                        {network.active_ap}
                        { "/" }
                        {network.inactive_ap + network.active_ap}
                        { " " }
                        active nodes
                    </p>
                    <p>
                        {network.clients}
                        { " " }
                        connected users
                    </p>
                    { checkRights(user) && !network.is_default && (
                        <div className='flex justify-end mt-1'>
                            <DeleteOutlined onClick={e => showConfirm(network.id, e)} />
                        </div>
                    )}
                </div>
            ))
            : 
            <p>No networks yet</p>
        }
        </div>
    </div>
  )
}

export default NetworkList