import { createBrowserRouter } from "react-router-dom";
import Clients from "views/Clients";
import Signin from "views/Auth/Signin";
import DefaultLayout from "components/Layouts/DefaultLayout";
import Alerts from "views/Alerts";
import Dashboard from "views/Dashboard";
import CaptivePortal from "views/CaptivePortal";
import NetworkPage from "views/Networks/NetworkPage";
import ErrorPage from "views/Auth/ErrorPage";
import MyTeam from "views/MyTeam";
import MyAccount from "views/MyAccount";
import Preferences from "views/Preferences";


const router = createBrowserRouter([
    {
        path: "",
        element: <DefaultLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Dashboard />,
            },
            {
                path: "/clients",
                element: <Clients />,
            },
            {
                path: "/alerts",
                element: <Alerts />,
            },
            {
                path: "/captive-portal",
                element: <CaptivePortal />
            },
            {
                path: "/networks/:id",
                element: <NetworkPage />
            },
            {
                path: "/my_team",
                element: <MyTeam />
            },
            {
                path: "/my_account",
                element: <MyAccount />
            },
            {
                path: "/my_preferences",
                element: <Preferences />
            },
        ]
    },
    {
        path: "/login",
        element: <Signin />,
        errorElement: <ErrorPage />
    }
]);

export default router;