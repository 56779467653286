import { useRouteError, Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div id="error-page" className="flex flex-col items-center justify-center p-6 lg:px-8">
      <h1 className="text-9xl font-semibold text-sky-950">{error.status}</h1>
      <p className="mt-2 text-2xl font-bold text-gray-900">
      Oops! {error.statusText || error.message}
      </p>
      <p>Sorry, an unexpected error has occurred.</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
              to="/login"
              className="flex justify-center text-sm bg-sky-900 
      px-3 py-2 text-md font-normal leading-6 text-white shadow-sm hover:bg-sky-800 focus-visible:outline focus-visible:outline-2 rounded-md
      focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all ease-in-out duration-300"
          >
              Back to Home
          </Link>
      </div>
    </div>
  );
}