import { useContext, useState, useEffect, createContext } from "react";
import axiosClient from "utils/axiosClient";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [networkData, setNetworkData] = useState(null)

  const [user, _setUser] = useState(null);
  const [accessToken, _setAccessToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  
  useEffect(() => {
    getCurrentUser();
  }, [accessToken])

  const getCurrentUser = async () => {
    try {
      if(accessToken){
        const { data } = await axiosClient.get('/me');
        _setUser(data?.data)
      }
    } catch (error) {}
  }

  const setAccessToken = (accessToken) => {
      _setAccessToken(accessToken);

      accessToken ? localStorage.setItem("ACCESS_TOKEN", accessToken) : localStorage.removeItem("ACCESS_TOKEN"); 
  };

  const setUser = (data) => {
      _setUser(data);
  };

  const logout = () => {
    localStorage.removeItem("ACCESS_TOKEN")
    window.location.href = '/login';
  }
  
  const value = {
    user,
    setUser,
    setAccessToken,
    accessToken,
    logout,
    networkData,
    setNetworkData
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};