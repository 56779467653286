import axios from "axios";
import toast from "react-hot-toast";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const REFRESH_TOKEN_URL = `${process.env.REACT_APP_BASE_URL_2}/oauth/v2/token`;
const MIN_TOKEN_REFRESH_INTERVAL = 2000; // 5 * 60 * 1000;

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const saveAuthData = (data) => {
    localStorage.setItem("ACCESS_TOKEN", data.access_token);
    localStorage.setItem("accessRefresh", data.refresh_token);
};

const getSavedAuthData = () => {
    const refreshToken = localStorage.getItem("meshRefresh");
    return { refreshToken };
  };

const redirectToLogin = () => window.location.href = "/";

export const saveTokens = (axiosData) => {
    const { access_token, refresh_token } = axiosData.data;
  
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    saveAuthData({ access_token, refresh_token });
    return Promise.resolve(axiosData);
};


// Refresh request itself
let previousRefreshTokenPromise = null;
let refreshTokenTimeout = null;
const refreshAuthToken = () => {
  const { refreshToken } = getSavedAuthData();
  // Do not execute if have no token
  if (!refreshToken) {
    return Promise.reject();
  }

  // Make just one refresh request for all failed requests
  if (previousRefreshTokenPromise) {
    return previousRefreshTokenPromise;
  }

  clearTimeout(refreshTokenTimeout);
  previousRefreshTokenPromise = axios.post(
    REFRESH_TOKEN_URL,
    {
      grant_type: "refresh_token",
      client_id: process.env.REACT_CLIENT_ID,
      refresh_token: refreshToken,
    },
  )
    .finally(() => refreshTokenTimeout = setTimeout(() => {
      clearTimeout(refreshTokenTimeout);
      previousRefreshTokenPromise = null;
    }, MIN_TOKEN_REFRESH_INTERVAL));

  return previousRefreshTokenPromise;
};


// Intercept unauth responses, refresh token and recall request
const refreshTokenInterceptor = (error) => {
    // Do not refresh on external fails or refresh request
    if (!error.config.url.includes(process.env.REACT_APP_BASE_URL) || error.config.url.includes(REFRESH_TOKEN_URL)) {
      return Promise.reject(error);
    }
  
    return refreshAuthToken()
      .then(saveTokens)
      .then((axiosData) => {
        error.config.headers.Authorization = axios.defaults.headers.common.Authorization;
        return axiosData;
      })
      .catch(() => {
        redirectToLogin();
        return Promise.reject(error);
      });
  };
  createAuthRefreshInterceptor(axios, refreshTokenInterceptor);
  
  // Set existed token to config
  axios.defaults.headers.common.Authorization = getSavedAuthData().token;





// My Interceptor. only this is working
axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },

    (error) => {
        try {
            if(error?.response){
                const { response } = error;

                // unauthorised user
                if (response.status === 401) {
                    localStorage.removeItem("ACCESS_TOKEN");
                    localStorage.removeItem("activeIndex");
                    window.location.href = '/login';
                    return error
                }
            } else if (error?.code === "ERR_NETWORK") {
                toast.error(error?.message)
            } else if (error?.code === "ECONNABORTED"){
                toast.error(error?.message)
            }
        } catch (error) {
            console.error(error);
        }

        throw error;
    }
);

export default axiosClient;
