import { Button as AntButton, ConfigProvider } from "antd";
import React from 'react'

function Button({ onClick, text, type="primary", htmlType="submit" }) {
  return (
    <ConfigProvider
        theme={{
            components: {
                Button: {
                colorPrimary: `#001529`,
                colorPrimaryHover: `#1A4C6D`,
                colorPrimaryActive: `#1A4C6D`,
                height: "40px"
            }},
        }}
    >
        <AntButton type={type} onClick={onClick} htmlType={htmlType}>{text}</AntButton>
    </ConfigProvider>
  )
}

export default Button