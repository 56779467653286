import { notification } from 'antd';

function Notification({ type, message, description}) {
    return notification[type]({
        message,
        description,
        style: {
          backgroundColor: "#fff",
          color: "#222",
          border: "1px solid black",
        },
      });
}

export default Notification