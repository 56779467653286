import { Popover, Spin, Tooltip } from "antd";
import { useState } from "react";
import checkRights from "utils/checkRights";
import getColor from "utils/getColor";
import b1 from "../../../assets/battery/b1.png"
import b2 from "../../../assets/battery/b2.png"
import b3 from "../../../assets/battery/b3.png"
import b4 from "../../../assets/battery/b4.png"

function DndItem({ item, notesListType, me, networkData }) {
    const [processItem, setProcessItem] = useState([])

    const getIcon = (item) => {
        // const color = getColor(item.status.toLowerCase(), item.is_gateway, this.isOnMap(item));
        const color = getColor(item.status.toLowerCase(), item.is_gateway);
        // if (this.isOnMap(item)) {
        //   return (
        //     <i className="ion-record" style={{ color }} />
        //   );
        // }
        return (
          <Tooltip title="The node is not on the map or there are no coordinates!">
            <i className="ion-record" style={{ color }} />
          </Tooltip>
        );
    };

    const getCellproviderShort = (item) => {
        if (item.cellprovider && item.cellprovider.trim() === "T-Mobile") return "TMB";
        if (item.cellprovider && item.cellprovider.trim() === "AT&T") return "ATT";
        if (item.cellprovider && item.cellprovider.trim() === "Verizon") return "VZW";
        return "ATT";
      };

    const getBatteryImg = (persent) => {
        if (persent <= 25) return b1;
        if (persent > 25 && persent <= 50) return b2;
        if (persent > 50 && persent <= 75) return b3;
        return b4;
      };

    return (
        <div
          className="dnd-item overflow-hidden rounded-md"
          id={item.id}
        >
          {
            item.isUpgraded
            || (processItem && processItem.findIndex(({ id }) => id === item.id) !== -1)
              ? (
                <Spin
                //   className={`reorder-spin-style ${this.getSpinColorClass(item.status.toLowerCase(), item.is_gateway)}`}
                />
              ) : 
              (<>
                {getIcon(item)}
              </>)
          }
          <div className="dnd-item-data flex justify-between">
            <div className="data-left">
              <div id={`n_name_${item.id}`} className='w-[70%]'>
                <h4 id={`n_name_h4_${item.id}`} className='text-ellipsis line-clamp-1 font-bold'>{item.name}</h4>
              </div>
              <span className='text-xs'>{item.mac.toUpperCase()}</span>
            </div>
            <div className="flex gap-2">
                {notesListType !== "all" && (
                <div className="data-middle flex flex-col text-xs">
                    {
                    (item.is_gateway && item.active_uplink && item.active_uplink.toLowerCase() === "eth") && (
                        <span className="gateway">ETH</span>
                    )
                    }
                    {
                    (item.is_gateway && item.active_uplink && item.active_uplink.toLowerCase() === "cell") && (
                        <p>
                        {/* <Icon type="sync" className="gateway_sync" /> */}
                        <span className="gateway">
                            {getCellproviderShort(item)}
                        </span>
                        </p>
                    )
                    }
                    {item.status.toLowerCase() !== "inactive" && (
                    <span className="active">
                        <nobr>
                        {Math.round(item.downloadRate, 0)}
                        <span>&#8595;</span>
                        {"/"}
                        {Math.round(item.uploadRate, 0)}
                        <span>&#8593;</span>
                        {" "}
                        <span
                            style={{
                            display:
                                Math.round(item.downloadRate, 0).toString().length
                                + Math.round(item.uploadRate, 0).toString().length
                                > 7
                                ? "none"
                                : "inline",
                            }}
                        >
                            Mbps
                        </span>
                        </nobr>
                    </span>
                    )}
                    <span className="clients">
                    <nobr>
                        {item.clients}
                        {" "}
                        users
                    </nobr>
                    </span>
                </div>
                )}
            {/* battery */}
            <div className="data-right">
              <div className="data-right-battery text-xs font-semibold">
                <span>
                  <nobr>
                    {item.battery || 0}
                    {"%"}
                  </nobr>
                </span>
                <img alt="energy" src={`${getBatteryImg(item.battery || 0)}`} />
              </div>
              { 
                checkRights(me, networkData)
                && !(processItem && processItem.length > 0 && processItem?.findIndex(({ id }) => id === item.id) !== -1)
                  ? (
                    <Popover
                      theme="dark"
                      overlayClassName="blackPopover"
                      // overlayStyle={{ ...ppStyles }}
                      content={(
                        <ul>
                          <li>
                          {/* onClick={() => this.modalEdit(item)} */}
                            <button type="button" id={item.id}>
                              <i className="ion-edit" />
                              Edit
                            </button>
                          </li>
                          {item.status === "Active" && (
                            <li>
                                {/* onClick={() => this.rebootAP(item)} */}
                              <button id={item.id} type="button">
                                <i className="ion-refresh" />
                                Reboot
                              </button>
                            </li>
                          )}
                          <li>
                            {/* <button id={item.id} type="button" onClick={() => this.showConfirm(item)}>
                              <i className="ion-trash-a" />
                              Delete
                            </button> */}
                          </li>
                          {/* {this.firmwareSelect(item)} */}
                        </ul>
                      )}
                      trigger="hover, focus"
                    >
                      <div className="menu-points">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul>
                      </div>
                    </Popover>
                  ) : (
                    <Popover
                      overlayClassName="blackPopover"
                      // overlayStyle={{ ...ppStyles }}
                      content={(
                        <ul>
                          <li>
                            {/* <button type="button" id={item.id} onClick={() => this.showNodeInfo(item)}>
                              Information
                            </button> */}
                          </li>
                        </ul>
                      )}
                      trigger="hover, focus"
                    >
                      <div className="menu-points">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul>
                      </div>
                    </Popover>
                  )}
            </div>
            </div>
  
          </div>
          
          
  
        </div>
      );
}
export default DndItem