import { useState } from "react";
import Sidebar from "../Sidebar";
import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import { useAuth } from "context/AuthContext";

const DefaultLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { accessToken } = useAuth();

    return accessToken ?
        (
            <main className="flex w-screen h-screen overflow-hidden bg-[#FDFDFD]">
                <Sidebar
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <section className="w-full flex-1 overflow-y-auto overflow-x-hidden">
                    <Navbar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main className={`px-8 py-8 mt-[80px] min-h-[calc(100vh-150px)]`}>
                        <Outlet />
                    </main>
                    <div className="bg-white px-8 py-5 flex justify-center">
                        <p className="text-gray-700">RENUMESH ©{new Date().getFullYear()}</p>
                    </div>
                </section>
            </main>
        )
        :
        <Navigate to="/login" />
};

export default DefaultLayout;
