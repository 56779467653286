export default function getColor(status, isGateway = false, isOnMap = true) {
    let rez = "";
    switch (status) {
      case "inactive":
        rez = "#FF0000";
        break;
      case "active":
        if (isGateway) {
          rez = "#4482FF";
        } else {
          rez = "#00b16a";
        }
        break;
      case "rebooted":
        rez = "#ffa500";
        break;
      case "offline":
        rez = "#FF0000";
        break;
      case "online":
        rez = "#4482FF";
        break;
      case "partial offline":
        rez = "#ffbf00";
        break;
      case "white":
        rez = "#fff";
        break;
      case "black":
        rez = "#000";
        break;
      default:
        rez = "#FF0000";
        break;
    }
    if (!isOnMap) rez = `${rez}4d`;
    return rez;
  }
  