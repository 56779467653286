import { Popover, Modal } from "antd"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";

const confirm = Modal.confirm;

function TopbarUser({  logout }) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const { user } = useAuth()

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };


  const showConfirm = (e) => {
    hide();
    const darkMode = localStorage.getItem("dark_mode");
    confirm({
      title: "Are you sure you want to logout?",
      onOk() {
        logout();
      },
      onCancel() {},
      okText: "Logout",
      cancelText: "Cancel",
      className: darkMode === "true" ? "darkModal" : "",
    });
  };

  return (
    <Popover
      content={
        <div className="w-[150px]">
            <button onClick={() => {
              navigate("/my_account")
              hide()
            }} className="w-[150px] cursor-pointer py-1 px-2 rounded-md hover:bg-gray-100 mb-1 text-left">My account</button>
            <button onClick={() => {
              navigate("/my_team")
              hide()
            }} className="w-[150px] cursor-pointer py-1 px-2 rounded-md hover:bg-gray-100 mb-1 text-left">My team</button>
            <button onClick={() => {
              navigate("/my_preferences")
              hide()
            }} className="w-[150px] cursor-pointer py-1 px-2 rounded-md hover:bg-gray-100 mb-1 text-left">Preferences</button>
            <button onClick={showConfirm} className="w-[150px] cursor-pointer py-1 px-2 rounded-md hover:bg-gray-100 mb-1 text-left">Logout</button>
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
        {/* {user && */}
          <div className="rounded-full border-2 border-black w-10 h-10 flex justify-center items-center cursor-pointer">
            <p className="uppercase text-3xl font-semibold">{user?.username[0]}</p>
          </div>
        {/* } */}
    </Popover>
  )
}
export default TopbarUser