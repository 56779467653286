import { Popover } from "antd"
import Dnd from "./DNDList"
import { useState } from "react"
import checkRights from "utils/checkRights"
import Button from "components/uielements/Button"
function APList({ network, networkAPs, me }) {

  const [activeAPId, setActiveAPId] = useState(networkAPs && networkAPs.length > 0 && networkAPs[0]?.id)
  return (
    <div className="">
        <Popover
        overlayClassName="blackPopover"
        // overlayStyle={{ ...ppStyles }}
        content={(
          <ul>
            <li>
              Sort by:
            </li>
            <li>
              <button
                type="button"
                // className={classNames(orderNetworkRules && orderNetworkRules.name && "selected")}
                // onClick={() => this.orderBy("name", networkId)}
              >
                {/* {
                  orderNetworkRules && orderNetworkRules.name && (
                    <i className={classNames(
                      orderNetworkRules && orderNetworkRules.name === "asc"
                        ? "ion-android-arrow-down selected"
                        : "ion-android-arrow-up selected",
                    )}
                    />
                  )
                } */}
                Name
              </button>
            </li>
            <li>
              <button
                type="button"
                // className={classNames(orderNetworkRules && orderNetworkRules.clients && "selected")}
                // onClick={() => this.orderBy("clients", networkId)}
              >
                {/* {
                  orderNetworkRules && orderNetworkRules.clients && (
                    <i className={classNames(
                      orderNetworkRules && orderNetworkRules.clients === "asc"
                        ? "ion-android-arrow-down selected"
                        : "ion-android-arrow-up selected",
                    )}
                    />
                  )
                } */}
                Users count
              </button>
            </li>
            <li>
              <button
                type="button"
                // className={classNames(orderNetworkRules && orderNetworkRules.status && "selected")}
                // onClick={() => this.orderBy("status", networkId)}
              >
                {/* {
                  orderNetworkRules && orderNetworkRules.status && (
                    <i className={classNames(
                      orderNetworkRules && orderNetworkRules.status === "asc"
                        ? "ion-android-arrow-down selected"
                        : "ion-android-arrow-up selected",
                    )}
                    />
                  )
                } */}
                Status
              </button>
            </li>
            <li>
              <button
                type="button"
                // className={classNames(orderNetworkRules && orderNetworkRules.downloadRate && "selected")}
                // onClick={() => this.orderBy("downloadRate", networkId)}
              >
                {/* {
                  orderNetworkRules && orderNetworkRules.downloadRate && (
                    <i className={classNames(
                      orderNetworkRules && orderNetworkRules.downloadRate === "asc"
                        ? "ion-android-arrow-down selected"
                        : "ion-android-arrow-up selected",
                    )}
                    />
                  )
                } */}
                Capacity
              </button>
            </li>
            <li>
              <button
                type="button"
                // className={classNames(orderNetworkRules && orderNetworkRules.software_version && "selected")}
                // onClick={() => this.orderBy("software_version", networkId)}
              >
                {/* {
                  orderNetworkRules && orderNetworkRules.software_version && (
                    <i className={classNames(
                      orderNetworkRules && orderNetworkRules.software_version === "asc"
                        ? "ion-android-arrow-down selected"
                        : "ion-android-arrow-up selected",
                    )}
                    />
                  )
                } */}
                Firmware Version
              </button>
            </li>
          </ul>
        )}
        trigger="hover, focus"
      >
        <div className="menu-points">
          <i className="ion-android-funnel" />
        </div>
      </Popover>

      <Dnd Notes={networkAPs} NotesAll={networkAPs} networkData={network} me={me} activeAPId={activeAPId} setActiveAPId={setActiveAPId} />
      {checkRights(me, network) && (
            <Button text="Add node to network" onClick={() => {}} />
          )}
    </div>
  )
}
export default APList