import { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import axiosClient from "utils/axiosClient";
import checkRights from "utils/checkRights";
import { useAuth } from "context/AuthContext";
import { MdModeEdit, MdDeleteOutline } from "react-icons/md";
import { FaRegClone } from "react-icons/fa6";
import Button from "components/uielements/Button";
import TabTitle from "components/uielements/TabTitle";
import FormModal from "components/uielements/FormModal";

function CaptivePortal() {

    const [alerts, setAlerts] = useState([])
    const [loading, setLoading] = useState(false)
    const [addCpModal, setAddCpModal] = useState(false)
    const [editCp, setEditCp] = useState(null)
    const { user } = useAuth()

    const getCaptivePortals = async () => {
        setLoading(true)
        try {
          const { data } = await axiosClient.get('/captive-portals');

          if(data) {
            setAlerts(data?.data?.items)
          }
        } catch(error) {}
        setLoading(false)
    }

    useEffect(() => {
        getCaptivePortals()
    }, [])

    const columns = [
        {
          title: "Name",
          dataIndex: 'name',
          key: "name",
        },
        {
          title: "Settings Type",
          key: "type",
          width: "40%",
          render: (object) => {
            let rez = "";
            switch (object.settings_type) {
              case "text": rez = <p>Text</p>; break;
              case "bundle": rez = <p>Resource Bundle</p>; break;
              case "uri": rez = <p>Rsync URI</p>; break;
              default: rez = <p>-</p>; break;
            }
            return rez;
          },
        },
        {
          title: "",
          key: "edit",
          width: "2%",
          render: (object) => {
            return (checkRights(user)
              && (object.status === "-" ? (
                <p>-</p>
              ) : (
                <Tooltip
                  title="Edit this captive portal configuration"
                >
                  <MdModeEdit id={object.id} style={{ cursor: "pointer" }} size={20} onClick={() => setEditCp(object)} className="cursor-pointer text-gray-600" />
                </Tooltip>
              ))
            );
          },
        },
        {
          title: "",
          key: "clone",
          width: "2%",
          render: (object) => {
            return (checkRights(user)
              && (object.status === "-" ? (
                <p>-</p>
              ) : (
                <Tooltip
                  title="Make a new captive portal configuration that is cloned from this configuration"
                >
                  <FaRegClone id={object.id} style={{ cursor: "pointer" }}
                    // onClick={() => clonePortal(object.id)}
                    size={20} className="cursor-pointer text-gray-600" 
                   />
                </Tooltip>
              ))
            );
          },
        },
        {
          title: "",
          key: "delete",
          width: "2%",
          render: (object) => {
            return (checkRights(user)
              && (object.status === "-" ? (
                <p>-</p>
              ) : (
                <Tooltip
                  title="Delete this captive portal configuration"
                >
                  <MdDeleteOutline id={object.id} style={{ cursor: "pointer" }}
                    // onClick={() => clonePortal(object.id)}
                    size={20} className="cursor-pointer text-gray-600"  />
                </Tooltip>
              ))
            );
          },
        },
      ];

    const handleCancel = () => {
      setAddCpModal(false)
      setEditCp(null)
    }

    const handleSubmit = async () => {}

    const formFields = [
      {
          name: "name",
          title: "Captive Portal Name",
          placeholder: "Enter Captive Portal Name",
          is_required: true,
          inputFieldType: "textField"
      },
      {
          name: "radius_server1",
          title: "Radius Server 1",
          placeholder: "Enter Radius Server 1",
          is_required: false,
          inputFieldType: "textField"
      },
      {
          name: "radius_server2",
          title: "Radius Server 2",
          placeholder: "Enter Radius Server 1",
          is_required: false,
          inputFieldType: "textField"
      },
      {
          name: "radius_secret",
          title: "Radius Secret",
          placeholder: "Enter Radius Secret",
          is_required: false,
          inputFieldType: "textField"
      },
      {
          name: "radius_auth_port",
          title: "Radius Authentication Port",
          placeholder: "Enter Authentication Port",
          is_required: false,
          inputFieldType: "textField"
      },
      {
          name: "radius_acct_port",
          title: "Radius Accounting Port",
          placeholder: "Enter Accounting Port",
          is_required: false,
          inputFieldType: "textField"
      },
      {
          name: "radius_nasid",
          title: "Radius Nas ID",
          placeholder: "Enter Nas ID",
          is_required: false,
          inputFieldType: "textField"
      },
      {
          name: "uam_domain",
          title: "Whitelisted Domains",
          placeholder: "",
          is_required: false,
          inputFieldType: "textField"
      },
      {
          name: "radius_nasid",
          title: "Whitelisted Domains (Wildcard)",
          placeholder: "",
          is_required: false,
          inputFieldType: "textField"
      },
  ];

    const initialValues = {
      name: editCp ? editCp?.name : "",
      radius_server1: editCp ? editCp?.radius_server1 : "",
      radius_server2: editCp ? editCp?.radius_server2 : "",
      radius_secret: editCp ? editCp?.radius_secret : "",
      radius_auth_port: editCp ? editCp?.radius_auth_port : "",
      radius_acct_port: editCp ? editCp?.radius_acct_port : "",
      radius_nasid: editCp ? editCp?.radius_nasid : "",
      uam_domain: editCp ? editCp?.uam_domain : "",
      interval: "60",
      uamaliasip: "1.0.0.1",
      uamaliasport: "80",
      uamlogoutip: "1.0.0.0",
      defessiontimeout: "3600",
      redir_ssl: false,
      external_splash_url: "",
      external_splash_secret: "",
    };

  return (
    <div className="text-sm">
        <TabTitle title="Captive Portal" />
        <div className="flex justify-between items-center mb-5">
          <h3 className="text-2xl font-semibold">Portal List</h3>
          <Button text="Create New" onClick={() => {setAddCpModal(true)}} />
        </div>

        {addCpModal && <FormModal
            handleSubmit={() => {}}
            handleCancel={handleCancel}
            title="Captive Portal"
            formFields={formFields}
            initialValues={initialValues}
            width="70%"
        />}
        {editCp && <FormModal
            handleSubmit={() => {}}
            handleCancel={handleCancel}
            title="Captive Portal"
            formFields={formFields}
            initialValues={initialValues}
            width="70%"
        />}

        <Table
            columns={columns}
            dataSource={alerts}
            loading={loading}
        />
    </div>
  )
}

export default CaptivePortal;