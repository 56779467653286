import {
    LineChart, Line, ResponsiveContainer, CartesianGrid, AreaChart, Area,
    XAxis, YAxis, Tooltip, Legend,
  } from "recharts";

function NetworkChart({ countC, countT, netData }) {
  return (
    <div className="border-[1px] rounded-md my-5 py-3">
        <ResponsiveContainer width="100%" height={450}>
            <AreaChart
                width={730}
                height={250}
                data={netData}
                margin={{
                top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Legend />

                {
                    countC < countT ? (
                        <Area connectNulls={false} type="monotone" name="Throughput" dataKey="throughput" stroke="#c55a10" fill="#c55a10" />
                    ) : (
                        <Area connectNulls={false} type="monotone" name="Capacity" dataKey="capacity" stroke="#c5e0b4" fill="#c5e0b4" />
                    )
                }
                {
                    countC < countT ? (
                        <Area connectNulls={false} type="monotone" name="Capacity" dataKey="capacity" stroke="#c5e0b4" fill="#c5e0b4" />
                    ) : (
                        <Area connectNulls={false} type="monotone" name="Throughput" dataKey="throughput" stroke="#c55a10" fill="#c55a10" />
                    )
                }
            </AreaChart>
            </ResponsiveContainer>
        </div>
  )
}
export default NetworkChart