import classnames from "classnames";
import { ErrorMessage } from "formik";
import { Input } from 'antd';
import { MdErrorOutline } from "react-icons/md";

/* eslint-disable react/prop-types */
const TextInput = ({
    type = "text",
    title,
    name,
    placeholder,
    requiredField = false,
    errors,
    values,
    handleChange,
    handleBlur,
    touched
}) => {
    return (
        <div className="mb-4 flex gap-2">
            <label
                htmlFor={name}
                className="block leading-6 text-gray-600 mb-3 text-sm w-[30%] text-right"
            >
                {title}
                {requiredField && <span className="text-red-800">*</span>}:
            </label>
            <div className="w-full">
                <Input
                    size="small"
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onChange={handleChange(name)}
                    onBlur={handleBlur(name)}
                    value={values[name]}
                    className={classnames(
                        "block w-full rounded-md border-0 py-[6px] text-gray-500 shadow-sm ring-1 ring-inset  placeholder:text-gray-300 focus:ring-inset  sm:text-sm sm:leading-6",
                        errors && errors[name] && touched && touched[name]
                            ? "ring-red-600 focus:ring-red-600 text-red-600"
                            : "ring-gray-400 focus:ring-blue-400"
                    )}
                />
            </div>

            <ErrorMessage name={name}>
                {(msg) => (
                <div className="text-xs text-red-500 text-left w-full flex gap-1 items-center mt-1">
                    <MdErrorOutline size={16} />
                    <p>{msg}</p>
                </div>
                )}
            </ErrorMessage>
        </div>
    );
};

export default TextInput;
