class NodesColors {
    constructor() {
        this.colors = [
          { down: "#ff0000", up: "#008000" },
          { down: "#ffa500", up: "#0000ff" },
          { down: "#ffff00", up: "#8b00ff" },
          { down: "#00FF00", up: "#282864" },
          { down: "#add8e6", up: "#c82828" },
        ];
      
        this.nodes = {};
      }
  
    getColor(nodeId) {
      if (!this.nodes[nodeId]) {
        this.nodes[nodeId] = this.colors[Object.keys(this.nodes).length % this.colors.length];
      }
      return this.nodes[nodeId];
    }
  }
  
  export default NodesColors;
  