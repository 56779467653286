import { useState, useEffect} from 'react'
import axiosClient from 'utils/axiosClient'
import NetworkList from './NetworkList'
import BasicMaker from 'components/GoogleMaps/BasicMaker';
import Stats from './Stats';
import TabTitle from 'components/uielements/TabTitle';

function Dashboard() {

  const [networks, setNetworks] = useState(null)
  const [loading, setLoading] = useState(false)
  const [topStats, setTopStats] = useState({
    networkTotal: 0,
    apTotal: 0,
    networkUserTotal: 0,
  })

  useEffect(() => {
    getNetworks()
  }, [])
  const getNetworks = async () => {
    setLoading(true)
    try {
      const response = await axiosClient.get(`/networks?limit=999`);

      if(response && response.status !== 401){
        const { data } = response
        const { data: sharedNetworks} = await axiosClient.get('/shared/companies/networks?limit=999');

        const networks2 = []
          if(data.data.items && data.data.items.length >0){
            networks2.push(...data.data.items)
          }

          if(sharedNetworks.data && sharedNetworks.data.length >0){
              sharedNetworks.data.forEach(net => {
                networks2.push(...net.networks)
              })
          }

        setNetworks(networks2 && networks2.sort((a, b) => a.name.localeCompare(b.name)))

        let networkTotal = 0
        let apTotal = 0;
        let networkUserTotal = 0;

        if(networks2){
          networks2.forEach((item) => {
            networkTotal += 1;
            apTotal += item.total_ap;
            networkUserTotal += item.clients;
          });

          setTopStats({
            apTotal: apTotal,
            networkTotal: networkTotal,
            networkUserTotal: networkUserTotal
          })
        }
      }
      
    } catch(error) { 
      // console.log(error) 
    }
    setLoading(false)
  }

  return (
    <main>
      <TabTitle title="Dashboard" />
      <Stats networks={networks} topStats={topStats} />

      <section className='flex flex-col gap-2 my-5 bg-white py-5 rounded-md shadow-sm'>
        <div className='mx-5'>
          <BasicMaker 
            networks={networks}
          />
        </div>
        <NetworkList networks={networks} />
      </section>
      
    </main>
  )
}

export default Dashboard