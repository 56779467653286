import { useEffect, useState } from "react";
import { Table, Tag, DatePicker, Select, Menu, Space, Dropdown, Button } from "antd";
import { format } from "date-fns";
import axiosClient from "utils/axiosClient";
import { MdOutlineArchive } from "react-icons/md";
import TabTitle from "components/uielements/TabTitle";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { DownOutlined } from "@ant-design/icons";

const Option = Select.Option

function Alerts() {

    const [alerts, setAlerts] = useState([])
    const [filteredAlerts, setFilteredAlerts] = useState(alerts)
    const [loading, setLoading] = useState(false)
    const [networks, setNetworks] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const getNetworks = async () => {
      setLoading(true)
      try {
        const response = await axiosClient.get(`/networks?limit=999`);
  
        if(response && response.status !== 401){
          const { data } = response
          const { data: sharedNetworks} = await axiosClient.get('/shared/companies/networks?limit=999');
  
          const networks2 = []
            if(data.data.items && data.data.items.length >0){
              networks2.push(...data.data.items)
            }
  
            if(sharedNetworks.data && sharedNetworks.data.length >0){
                sharedNetworks.data.forEach(net => {
                  networks2.push(...net.networks)
                })
            }
  
          setNetworks(networks2?.toSorted((a, b) => a.name.localeCompare(b.name)))

        }
        
      } catch(error) {
        // console.log(error)
      }
      setLoading(false)
    }

    const getAlerts = async () => {
        setLoading(true)
        try {
          const { data } = await axiosClient.get('/alerts?limit=999');

          if(data) {
            setFilteredAlerts(data?.data?.items)
            setAlerts(data?.data?.items)  
          }
        } catch (error) {}
        setLoading(false)
    }

    const archiveAlert = async (id) => {
      setLoading(true)
      try {
        const { data } = await axiosClient.delete(`/alerts/${id}`)

        if(data && data.code === 200){
          toast.success("Alert has been archived")
        }
        
      } catch (error) {
        toast.error(`${error.message}`)
      }
      setLoading(false)
    }

    useEffect(() => {
      getNetworks()
        getAlerts()
    }, [])

    const columns = [
        {
          title: "Created At",
          dataIndex: 'created_at',
          key: "created_at",
          render: (_, record) => record.created_at && format(record.created_at, "dd/MM/yyyy hh:mm a"),
          sorter: (a, b) => a.created_at - b.created_at,
        },
        {
          title: "Level",
          dataIndex: 'type',
          key: "type",
          render: (_, record) => (
            record.type === "WARNING" ?
            <Tag color="volcano" className="text-xs">{record.type}</Tag>
             : record.type === "INFO" ?
             <Tag color="blue" className="text-xs">{record.type}</Tag>
             :
             <Tag color="green" className="text-xs">{record.type}</Tag>
          )
        },
        {
          title: "Message",
          dataIndex: 'message',
          key: "message",
          render: (_, record) => <p>{record.message.split(record.access_point_name)[0]} <Link to={`/networks/${record.network_id}`} className="text-blue-400">{record.access_point_name}</Link> {record.message.split(record.access_point_name)[1]}</p>
        },
        {
          title: "Archive",
          dataIndex: 'created_at',
          key: "created_at",
          render: (_, record) => <MdOutlineArchive size={20} className="text-gray-500 cursor-pointer" onClick={() => archiveAlert(record.id)} />,
        },
      ];

      const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
          setSelectedRowKeys(newSelectedRowKeys);
        },
      };

      const handleBulkAction = (action) => {
        setLoading(true);
        setTimeout(() => {
          // Perform the bulk action
          // console.log(`Performing ${action} on selected rows:`, selectedRowKeys);
          setSelectedRowKeys([]);
          setLoading(false);
        }, 1000);
      };
    
      const bulkMenu = (
        <Menu>
          <Menu.Item onClick={() => handleBulkAction('delete')}>
            Delete
          </Menu.Item>
          <Menu.Item onClick={() => handleBulkAction('archive')}>
            Archive
          </Menu.Item>
        </Menu>
      );

  return (
    <div>
      <TabTitle title={alerts && alerts.length > 0 ? `Alerts (${alerts.length}+)` : "Alerts"} />

      <div className="flex gap-3">
        <DatePicker className="h-[32px]" onChange={(e) => {
            if(e){
              const filteredAlerts2 = alerts && alerts?.filter(alert => format(alert.created_at, "dd/MM/yyyy") === format(e.$d, "dd/MM/yyyy"))
              setFilteredAlerts(filteredAlerts2)
            } else {
              setFilteredAlerts(alerts)
            }
          }} />

          <Select onChange={(e) => {
              if(e){
                const filteredAlerts2 = alerts && alerts?.filter(alert => alert.type === e)
                setFilteredAlerts(filteredAlerts2)
              } else {
                setFilteredAlerts(alerts)
              }
              }}
              defaultValue={null}
              className="w-[150px]"
            >
              <Option value={null}>Filter Type</Option>
              <Option value={"INFO"}>INFO</Option>
              <Option value={"WARNING"}>WARNING</Option>
            </Select>

            <Select onChange={(e) => {
                if(e){
                  const filteredAlerts2 = alerts && alerts?.filter(alert => alert.network_id === e)
                  setFilteredAlerts(filteredAlerts2)
                } else {
                  setFilteredAlerts(alerts)
                }
                }}
                defaultValue={null}
                className="w-[150px]"
              >
                <Option value={null}>Filter Network</Option>
                {networks && networks.length > 0 && networks?.map(net => (
                  <Option key={net.id} value={net.id}>{net.name}</Option>
                ))}
            </Select>

            <Space style={{ marginBottom: 16 }}>
              <Dropdown overlay={bulkMenu} disabled={selectedRowKeys.length === 0}>
                <Button>
                  Bulk Actions <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
      </div>

      <div className="my-2 border-[1px] overflow-hidden">
        <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection}}
              columns={columns}
              dataSource={filteredAlerts}
              loading={loading}
              rowKey={(record) => record.id}
          />
      </div>

    </div>
  )
}

export default Alerts