import {
    LineChart, Line, ResponsiveContainer, CartesianGrid,
    XAxis, YAxis, Tooltip, Legend,
  } from "recharts";
import { Select } from "antd";

const Option = Select.Option

function SolarPowerChart({ helpData, apList, solarGraphData }) {

  return (
    <div className="border-[1px] my-5 py-3 rounded-md">
        <div id="selectnode">
            <Select
            //   style={{ minWidth: maxPx }}
                getPopupContainer={() => document.getElementById("selectnode")}
            //   value={nodeId || "all"}
                value={"all"}
                onChange={() => {}}
                className="chartSelect"
            >
                <Option
                // className={optionClassName}
                key={0}
                value="all"
                >
                Input power
                </Option>
                {
                apList.map(item => (
                    // (nodeIdsArray.indexOf(item.id) !== -1)
                    // ? (
                        <Option
                        // className={optionClassName}
                        key={item.id}
                        value={item.id}
                        >
                        {item.name}
                        </Option>
                    // )
                    // : ""
                ))
                }
            </Select>
        </div>

        <div className="chart-block flex items-center gap-0 my-5">
            <p className="[writing-mode:sideways-lr]">
                <nobr>
                Input power (W)
                </nobr>
            </p>

        <ResponsiveContainer width="100%" height={250}>
            <LineChart
                width={730}
                height={250}
                data={solarGraphData}
                margin={{
                top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <Legend />
                    <YAxis />
                    {<Tooltip
                    isAnimationActive={false}
                    content="Tooltip"
            />}
              {helpData?.map(item => (
                <Line name={item.name} type="monotone" key={item.id} dataKey={item.id} stroke={item.color} />
              ))}
            </LineChart>
        </ResponsiveContainer>
        </div>
        </div>
  )
}
export default SolarPowerChart