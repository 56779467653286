import { Form, Input } from "antd"
import toast from "react-hot-toast";
import { useAuth } from "context/AuthContext";
import { Navigate } from "react-router-dom";
import Button from "components/uielements/Button";
import LOGO from "assets/sidebar_logo.png"
import axios from "axios";

function Signin() {
    const { accessToken, setAccessToken } = useAuth()

    const onFinish = async (values) => {
      const payload = {
          grant_type: process.env.REACT_APP_GRANT_TYPE,
          client_id: process.env.REACT_CLIENT_ID,
          ...values
      }

      try {
          const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_2}/oauth/v2/token`, payload);
          if(data){
              setAccessToken(data.access_token)
          }
          
        } catch (error) {
          if(error.response){
            if(error.response.status === 400){
              toast.error(error.response.data.error_description)
            }
          }
      }
    };

    const onFinishFailed = (errorInfo) => {};

    if (accessToken){
        return <Navigate to="/" />;
    }

  return (
    <div className="w-screen h-screen flex">
      <div className="auth-carousel hidden md:block md:w-3/5 h-screen"
        ></div>
      <div className="w-full md:w-[550px] lg:w-[750px] bg-white flex flex-col items-center justify-center">
            <div>
              <img src={LOGO} width={120} alt="RENUMESH" />
            </div>
          <Form
            className="flex flex-col mt-10"
            name="basic"
            labelCol={{ span: 8, }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
          <Form.Item
            // label="Email"
            className="w-full"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input placeholder="Email" className="w-full" style={{ width: '320px' }} />
          </Form.Item>

          <Form.Item
            // label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password placeholder="Password" style={{ width: '320px' }}  />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 18,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" text="Submit" />
          </Form.Item>
        </Form>
      </div>
    </div>
)
}

export default Signin