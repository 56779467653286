import { Helmet, HelmetProvider } from 'react-helmet-async';

function TabTitle({title}) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title} - RENUMESH</title>
      </Helmet>
    </HelmetProvider>
  )
}
export default TabTitle