import {
    LineChart, Line, ResponsiveContainer, CartesianGrid,
    XAxis, YAxis, Tooltip, Legend,
  } from "recharts";

function ClientChart({ clientsData, helpSsidData }) {
  return (
    <div className="border-[1px] my-5 py-3 rounded-md">
        <ResponsiveContainer width="100%" height={250}>
            <LineChart
                width={730}
                height={250}
                data={clientsData}
                margin={{
                top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <Legend />
                    <YAxis />
                    {<Tooltip
                    isAnimationActive={false}
                    content="Tooltip"
            />}
              {helpSsidData?.map(item => (
                <Line name={item.name} type="monotone" key={item.id} dataKey={item.id} stroke={item.color} />
              ))}
            </LineChart>
        </ResponsiveContainer>
        </div>
  )
}
export default ClientChart