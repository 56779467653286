function convertToGbps(val) {
    return (+val / 1000).toString();
  }
  
  function convertToKbps(val) {
    return (+val * 1000).toString();
  }
  export default function getUpDownData(networkData) {
    let upload = "0";
    let points = "Mbps";
    let uploadFix = 2;
    let download = "0";
    let downloadFix = 2;
    if (!networkData) return {};
    if (networkData) {
      upload = networkData.gateways_upload_rate ? networkData.gateways_upload_rate.toString() : "0";
      download = +networkData.gateways_download_rate ? networkData.gateways_download_rate.toString() : "0";
    }
    if (+upload > +download) {
      if (upload.split(".")[0].length > 3) {
        // convert upload
        points = "Gbps";
        upload = convertToGbps(upload);
        download = convertToGbps(download);
      } else if (upload < 1 && upload > 0) {
        points = "Kbps";
        upload = convertToKbps(upload);
        download = convertToKbps(download);
      }
    } else if (download.split(".")[0].length > 3) {
      // convert download
      points = "Gbps";
      download = convertToGbps(download);
      upload = convertToGbps(upload);
    } else if (download < 1 && download > 0) {
      points = "Kbps";
      download = convertToKbps(download);
      upload = convertToKbps(upload);
    }
  
    if (upload.split(".")[0].length <= 1) {
      uploadFix = 2;
    } else if (upload.split(".")[0].length === 2) {
      uploadFix = 1;
    } else {
      uploadFix = 0;
    }
  
    if (download.split(".")[0].length <= 1) {
      downloadFix = 2;
    } else if (download.split(".")[0].length === 2) {
      downloadFix = 1;
    } else {
      downloadFix = 0;
    }
    upload = +upload;
    download = +download;
    return {
      upload: upload.toFixed(uploadFix),
      download: download.toFixed(downloadFix),
      points,
    };
  }
  