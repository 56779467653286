import moment from "moment";
import { format, addHours } from 'date-fns';
// import { utcToZonedTime } from 'date-fns-tz';
import { formatInTimeZone } from 'date-fns-tz'

function localizeTime(time, date = false) {
  return format(addHours(date, 3), 'yyyy-MM-dd HH:mm:ss');
}

function localizeTimeDisplay(time, date = false) {
  return format(addHours(date, 3), 'yyyy-MM-dd HH:mm:ss');
}

function getMinutesNow(time) {
  // let minutesNow = localizeTime(time).format("mm");
  let minutesNow = format(time, "mm");
  minutesNow -= (minutesNow % 5);
  if (minutesNow.toString().length === 1) minutesNow = `0${minutesNow.toString()}`;
  return minutesNow;
}


export {
  localizeTime,
  localizeTimeDisplay,
  getMinutesNow,
};
