import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import checkRights from 'utils/checkRights';
import { isEqual, cloneDeep } from "lodash";
import { arrAllUniq, arrUniq, getItemStyle } from './utils';
import DndItem from './DndItem';
import getColor from 'utils/getColor';

const Dnd = ({
  Notes,
  NotesAll,
  activeAPId,
  networkData,
  notesListType,
  me,
  setActiveAPId,
  modalCreate,
  orderNodes,
  onDragEnd,
}) => {
  const [orderRules, setOrderRules] = useState([])

  const NotesView = Notes?.length > 0 ? arrUniq(Notes, networkData.id, orderRules, isEqual) : [];
  const NotesViewAll = NotesAll?.length > 0 ? arrAllUniq(NotesAll) : [];

  const getListStyle = (isDraggingOver) => {
    let bgColor = "transparent";
    if (isDraggingOver) bgColor = "#9CEE90";
    return {
      background: bgColor,
      borderRadius: "5px",
    };
  };


  return networkData ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="dnd-nodes-list bg-white">
        <div
          className={classNames(
            'current-network-nodes border-[1px] border-gray-400 rounded px-3 py-3 max-h-[calc(80vh-180px)] overflow-y-scroll mb-5',
            notesListType === 'all' ? 'current-network-nodes-small' : 'current-network-nodes-big',
          )}
          id="current"
        >
          <Droppable
            isDropDisabled={!checkRights(me, networkData)}
            scrollable="true"
            key={`net_${networkData.id}`}
            droppableId={`${networkData.id}`}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classNames(
                  NotesViewAll.length > 0 && notesListType === 'all' ? 'network-nodes-bordered-small' : '',
                  'network-nodes-bordered',
                )}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className="network-title-block font-bold my-2 mb-3">
                  <div
                    className="network-title"
                    onClick={() =>
                      setActiveAPId({ activeNetworkId: networkData.id, networkLocalData: networkData })
                    }
                  >
                    <nobr>
                      {networkData.name}
                    </nobr>
                  </div>
                  {/* <div>{orderNodes(networkData.id)}</div> */}
                </div>
                {NotesView.length > 0 &&
                  NotesView.map((item, index) => {
                    const color = getColor(item?.status.toLowerCase(), item.is_gateway);
                    return (<Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={!checkRights(me, networkData)}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          onClick={(e) => {
                            if (e.target.tagName !== 'LI' && e.target.tagName !== 'BUTTON') {
                              setActiveAPId({ id: item.id });
                            }
                          }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, activeAPId, item, color)}
                          className={`overflow-hidden px-3 py-2 rounded-md my-1`}
                        >
                          <DndItem item={item} notesListType="network" me={me} networkData={networkData} />
                        </div>
                      )}
                    </Draggable>
)})}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/*  */}
        </div>
        {NotesViewAll.length > 0 && notesListType === 'all' ? (
          <div className="current-network-nodes current-network-nodes-small" id="netAll">
            {NotesViewAll.map((network) =>
              network.id !== networkData.id ? (
                <Droppable
                  isDropDisabled={!checkRights(me, network)}
                  scrollable="false"
                  key={`netAll_${network.id}`}
                  droppableId={`netAll_${network.id}`}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="network-nodes-bordered network-nodes-bordered-small"
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <div className="network-title-block">
                        <div
                          className="network-title"
                          onClick={() =>
                            setActiveAPId({ activeNetworkId: network.id, networkLocalData: network })
                          }
                        >
                          <nobr>{network.name}</nobr>
                        </div>
                        <div>{orderNodes(network.id)}</div>
                      </div>
                      {network.aps.length > 0 &&
                        network.aps.map((item, index) => (
                          <Draggable
                            key={`all_${item.id}`}
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={!checkRights(me, network)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                onClick={(e) => {
                                  if (e.target.tagName !== 'LI' && e.target.tagName !== 'BUTTON') {
                                    setActiveAPId({ id: item.id });
                                  }
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, activeAPId, item)}
                              >
                                <DndItem item={item} notesListType="network" me={me} networkData={networkData} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ) : (
                ''
              )
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </DragDropContext>
  ) : (
    ''
  );
};

export default Dnd;
